export default {
  data() {
    return {
      event: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        type: "list",
        mode: "month",
        details: {},
        list: [],
        calendarList: [],
        meta: {},
        filters: {
          users: {
            list: [],
            selected: [],
          },
          companies: undefined,
          status: {
            list: [
              {
                label: "Em aberto",
                value: "Em aberto",
              },
              {
                label: "Cancelado",
                value: "Cancelado",
              },
              {
                label: "Pendente",
                value: "Pendente",
              },
              {
                label: "Pago",
                value: "Pago",
              },
              {
                label: "Realizado",
                value: "Realizado",
              },
              {
                label: "Recebido",
                value: "Recebido",
              },
            ],
            selected: [
              "Em aberto",
              "Pendente",
              "Pago",
              "Realizado",
              "Recebido",
            ],
          },
          priority: {
            list: [
              {
                label: "Baixa",
                value: "Baixa",
              },
              {
                label: "Média",
                value: "Média",
              },

              {
                label: "Alta",
                value: "Alta",
              },
            ],
            selected: [],
          },
          type: {
            list: [],
            selected: [],
          },
          period: {
            selected: [],
          },

          price: {
            min: "",
            max: "",
          },
          month: "",
          year: "",
          voyageId: "",
          searchTerm: "",
          date: "",
          dueDate: "",
          order: "desc",
          orderBy: "created",
        },
      },
      loadingCalendar: false,
    };
  },
  methods: {
    changeEventsPage(current) {
      this.event.pagination.page = current;
      this.getEvents();
    },
    changeEventsPageSize(current, pageSize) {
      this.event.pagination.page = current;
      this.event.pagination.perPage = pageSize;
      this.getEvents();
    },
    eventTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.event.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.event.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getEvents();
    },
    getEvents(samePage) {
      let filters = "";

      let queryParams = new URLSearchParams("");
      history.pushState(null, null, "");

      this.event.loading = true;

      if (!this.$root.isAdmin()) {
        filters += `&user_id=${this.$store.state.userData.id}`;
      } else {
        // dont show financial information to 23,38,39
        if (
          this.$store.state.userData.id == 23 ||
          this.$store.state.userData.id == 38 ||
          this.$store.state.userData.id == 3 ||
          this.$store.state.userData.id == 39
        ) {
          filters += `&user_id=${this.$store.state.userData.id}`;
        } else {
          if (this.event.filters.users.selected.length > 0) {
            filters += `&user_id=${this.event.filters.users.selected}`;
          }
        }
      }

      if (this.event.filters.searchTerm) {
        filters += `&s=${this.event.filters.searchTerm}`;
      }

      if (this.event.filters.voyageId) {
        filters += `&voyage_id=${this.event.filters.voyageId}`;
      }

      if (typeof this.event.filters.month != "object") {
        if (this.event.filters.year) {
          filters += `&year=${this.event.filters.year}`;
        }
      }

      if (this.event.filters.date) {
        if (typeof this.event.filters.date == "object") {
          filters += `&date=${this.formatDateModel(
            this.event.filters.date,
            "yyyy-MM-dd"
          )}`;
        } else {
          filters += `&date=${this.event.filters.date}`;
        }
      }

      if (this.event.filters.dueDate) {
        filters += `&due_date=${this.event.filters.dueDate}`;
      }

      if (this.event.filters.month) {
        if (typeof this.event.filters.month == "object") {
          filters += `&month=${this.formatDateModel(
            this.event.filters.month,
            "M"
          )}`;

          filters += `&year=${this.formatDateModel(
            this.event.filters.month,
            "yyyy"
          )}`;
        } else {
          filters += `&month=${this.event.filters.month}`;
        }
      }

      if (this.event.filters.price.min || this.event.filters.price.max) {
        let min = this.event.filters.price.min;
        let max = this.event.filters.price.max;

        if (min) {
          if (!max) {
            max = "500000";
          }
        }

        if (max) {
          if (!min) {
            min = "0";
          }
        }

        filters += `&price-range=${min}-${max}`;
      }

      if (this.event.filters.status.selected.length > 0) {
        filters += `&status=${this.event.filters.status.selected}`;
      }

      if (this.event.filters.companies) {
        filters += `&company_id=${this.event.filters.companies}`;
      }

      if (this.event.filters.type.selected.length > 0) {
        filters += `&type=${this.event.filters.type.selected}`;
      }

      if (this.event.filters.period.selected.length > 0) {
        filters += `&period=${this.event.filters.period.selected[0]}|${this.event.filters.period.selected[1]}`;
        queryParams.set(
          "period",
          `${this.event.filters.period.selected[0]}|${this.event.filters.period.selected[1]}`
        );
      }

      if (this.event.filters.priority.selected.length > 0) {
        filters += `&priority=${this.event.filters.priority.selected}`;
      }

      if (this.event.filters.users.selected.length > 0) {
        filters += `&user_id=${this.event.filters.users.selected}`;
      }

      filters += `&order=${this.event.filters.order}&order-by=${this.event.filters.orderBy}`;

      this.$http
        .get(
          `/event/${this.event.type}?page=${this.event.pagination.page}&per_page=${this.event.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.event.pagination.page = 1;
          }
          this.event.pagination.total = data.meta.total;
          this.event.pagination.totalPages = data.meta.total_pages;

          if (this.event.type == "list") {
            this.event.list = data.data;
          } else {
            this.event.calendarList = data.data;
          }

          this.event.loading = false;
          this.loadingCalendar = false;
          this.event.meta = data.meta;

          if (this.$route.name === "list") {
            this.excelFile.data = data.data;
            this.excelFile.footer = [];
            this.excelFile.footer.push(" ");
            //   // this.excelFile.footer.push(
            //   //   `Nº ATIVIDADES: ${data.meta.total}`
            //   // );

            //   // this.excelFile.footer.push(
            //   //   `VALOR TOTAL TODAS ATIVIDADES: R$ ${data.meta.total_value}`
            //   // );

            //   // this.excelFile.footer.push(" ");

            //   // this.excelFile.footer.push(
            //   //   `N° ATIVIDADES EM ABERTO:  ${data.meta.total_open}`
            //   // );

            //   // this.excelFile.footer.push(
            //   //   `VALOR TOTAL EM ABERTO: R$ ${data.meta.total_open_value}`
            //   // );

            //   // this.excelFile.footer.push(" ");

            //   // this.excelFile.footer.push(
            //   //   `N° ATIVIDADES PENDENTES: ${data.meta.total_pending}`
            //   // );

            //   // this.excelFile.footer.push(
            //   //   `VALOR TOTAL ATIVIDADES PENDENTES: R$ ${data.meta.total_pending_value}`
            //   // );

            //   // this.excelFile.footer.push(" ");

            //   // this.excelFile.footer.push(
            //   //   `N° ATIVIDADES PAGAS:  ${data.meta.total_paid}`
            //   // );

            //   // this.excelFile.footer.push(
            //   //   `VALOR TOTAL ATIVIDADES PAGAS: R$ ${data.meta.total_paid_value}`
            //   // );

            //   //

            //   this.excelFile.footer.push(" ");

            this.excelFile.footer.push(
              `VALOR TOTAL FEDERAL: R$ ${data.meta.total_federal}`
            );

            this.excelFile.footer.push(
              `VALOR TOTAL ATIVIDADES  PENDENTES FEDERAL: R$ ${data.meta.total_federal_pending}`
            );

            this.excelFile.footer.push(
              `VALOR TOTAL ATIVIDADES PAGAS FEDERAL: R$ ${data.meta.total_federal_paid}`
            );

            //

            this.excelFile.footer.push(" ");

            this.excelFile.footer.push(
              `VALOR TOTAL  PRUDENTE: R$ ${data.meta.total_prudente}`
            );

            this.excelFile.footer.push(
              `VALOR TOTAL ATIVIDADES PENDENTES PRUDENTE: R$ ${data.meta.total_prudente_pending}`
            );

            this.excelFile.footer.push(
              `VALOR TOTAL ATIVIDADES PAGAS PRUDENTE: R$ ${data.meta.total_prudente_paid}`
            );

            //

            this.excelFile.footer.push(" ");

            this.excelFile.footer.push(
              `VALOR TOTAL BANDEIRANTES: R$ ${data.meta.total_bandeirantes}`
            );

            this.excelFile.footer.push(
              `VALOR TOTAL ATIVIDADES PENDENTES BANDEIRANTES: R$ ${data.meta.total_bandeirantes_pending}`
            );

            this.excelFile.footer.push(
              `VALOR TOTAL ATIVIDADES PAGAS BANDEIRANTES: R$ ${data.meta.total_bandeirantes_paid}`
            );
          }
        })
        .catch(({ response }) => {
          this.loadingCalendar = false;

          this.event.list = [];
          this.event.calendarList = [];

          this.event.loading = false;
          this.event.meta = response.data.meta;
          this.excelFile.data = [];
          this.event.pagination.total = response.data.meta.total;
          this.event.pagination.totalPages = response.data.meta.total_pages;

          if (this.event.type == "list") {
            this.event.list = [];
          } else {
            this.event.calendarList = [];
          }
        });
    },
  },
};
